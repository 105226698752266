<template>
  <div>
    <div v-show="!loading">
      <div class="tab-bar">
        <h2>{{ pageTitle }}</h2>
        <div class="action">
          <v-switch
            :loading="form.$busy"
            v-model="form.content"
            @click.prevent="save"
          ></v-switch>
        </div>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="hideSnackbar">OK</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import Form from '@/utils/form'
import get from 'lodash/get'

export default {
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    pageType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      form: new Form({
        type: this.pageType,
        content: null,
      }),
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const response = await this.$api.get(`settings/pages/${this.form.type}`)
        this.form.content = get(response, 'data.data.content')
      } finally {
        this.loading = false
      }
    },
    async save() {
      try {
        this.form.$busy = true
        if (this.form.content) {
          this.form.content = '1'
        } else {
          this.form.content = '0'
        }

        await this.$api.post('settings/pages', this.form.$data())
        this.snackbar = {
          show: true,
          message: 'Settings updated successfully!',
          color: 'success',
        }
      } catch (err) {
        this.snackbar = {
          show: true,
          message: 'Settings update failed!',
          color: 'success',
        }
      } finally {
        this.form.$busy = false
      }
    },
    hideSnackbar() {
      this.snackbar = {
        show: false,
        message: '',
        color: '',
      }
    },
  },
}
</script>
<style scoped>
.tab-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
}
</style>
